<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
// document.title="江西应急管理协会"

export default {
  name: 'App',

}
</script>

<style>

</style>
